/* eslint-disable max-len */
import React                       from 'react';
import { navigate }                from 'gatsby';
import { Button, ButtonPropsType } from '../lib/components/button/button';
import { Divider }                 from '../lib/components/divider/divider';
import { Image }                   from '../lib/components/image/image';
import { Title }                   from '../lib/components/title/title';
import { Wrapper }                 from '../lib/components/wrapper/wrapper.component';
import { SiteLayout }              from '../lib/container/site-layout/site-layout';
import { TopBar }                  from '../lib/container/top-bar/top-bar';
import { useGetImgSet }            from '../lib/util/get-images';
import {
    categories,
    CategoryType,
    LinkType,
    PublicationItem,
    Publications,
    publications
} from './component/publications';

const MainItem = (props: {
    latest: PublicationItem;
    cat: CategoryType;
   count: number;
}) => {
    const img = useGetImgSet([ props.latest.src ]);
    const handleClick = () => {
        if (!props.cat.latestIssue) {
            navigate(props.cat.link);
        } else
        if (props.latest.link.includes('http')) {
            window.open(props.latest.link, '_blank');
        } else {
            navigate(props.latest.link);
        }
    };

    const getLink = (link: string) => {
        if (link.includes('http')) {
            window.open(link, '_blank');
        } else {
            navigate(link);
        }
    };

    const getLinkOptions = (link: LinkType): ButtonPropsType => {
        if (link === 'latest') {
            return { children: <>Latest issue</>, onClick: () => getLink(props.latest.link) };
        }
        return {
            children: <>{ link.title.replace('All', props.count.toString()) }</>,
            onClick:  () => getLink(link.link)
        };
    };
    return (
        <Wrapper padding="xs" fullWidth styles={ { minHeight: '100%' } }>

            <Wrapper
                radius="lg"
                style={ { backgroundColor: props.cat.color } }
                padding="xxl"
                align="left"
                // valign="center"
                sm={ { direction: 'row', padding: 'md' } }
                xsm={ { direction: 'column' } }
                styles={ { minHeight: '100%' } }
                row
            >

                <Image
                    src={ img[ props.latest.src ] }
                    radius="md"
                    margin="xl"
                    maxWidth="25rem"
                    onClick={ handleClick }
                    style={ { width: '100%' } }
                    flex={ 1 }
                />
                <Wrapper flex={ 1 } sm={ { flex: 3 } }>
                    <Title margin="md" size="h3" lg={ { size: 'h4' } }>{ props.cat.title }</Title>
                    { /* <Text margin="lg" size="md">{ props.cat.description }</Text> */ }
                    <Wrapper row style={ { margin: '0 auto', flexWrap: 'wrap', width: '100%' } }>
                        { props.cat.primaryLink && <Button size="lg" margin="xs" rounded { ...getLinkOptions(props.cat.primaryLink) } /> }
                        { props.cat.secondaryLink && <Button size="lg" viewType="transparent" { ...getLinkOptions(props.cat.secondaryLink) } /> }
                    </Wrapper>
                    { /* <Wrapper row style={ { margin: '0 auto', flexWrap: 'wrap', width: '100%' } }> */ }
                    { /*    <Button margin="xs" size="lg" rounded onClick={ handleClick }>{ props.cat.latestIssue ? 'Latest issue' : 'Reade more' }</Button> */ }
                    { /*    { props.cat.latestIssue && props.cat.link && <Button size="lg" viewType="transparent" onClick={ () => navigate(props.cat.link) }>{ `${ props.count } issuess` }</Button> } */ }
                    { /* </Wrapper> */ }
                </Wrapper>
            </Wrapper>
            <Wrapper />
        </Wrapper>
    );
};

const NewStartPage = () => (
    <SiteLayout>
        <Wrapper fullWidth style={ { minWidth: '100%' } }>
            <TopBar line />
            <Wrapper radius="lg" align="left" padding="xl" style={ { paddingLeft: 0 } } fullWidth>
                <h1 style={ {
                    fontSize:     '12vw',
                    lineHeight:   0.9,
                    marginBottom: '4rem',
                    maxWidth:     '100vw',
                    // textTransform: 'uppercase',
                    textAlign:    'left',
                    alignItems:   'center',
                    float:        'left'
                } }
                >
                    PUBLISHING
                    <br />
                    HOUSE
                </h1>

            </Wrapper>

            <Wrapper columns={ 2 } row md={ { columns: 1 } } style={ { alignItems: 'stretch' } }>
                { categories.map((cat) => {
                    if (cat.key === '') {
                        return;
                    }
                    const list = publications.filter((item) => item.type === cat.key);
                    return (
                        <MainItem
                            key={ cat.key }
                            cat={ cat }
                            count={ list.length }
                            latest={ list[ 0 ] }
                        />
                    );
                }) }
            </Wrapper>
            <Divider transparent margin="md" />
            <Title
                size="h1"
                md={ { size: 'h2' } }
                xsm={ { size: 'h3' } }
                style={ {
                    // fontSize:      '3vw',
                    lineHeight:    1,
                    marginBottom:  '4rem',
                    maxWidth:      '100vw',
                    textTransform: 'uppercase',
                    textAlign:     'left',
                    alignItems:    'center',
                    float:         'left'
                } }
            >
                The main idea is having a digital bookshelf for our community members to easily access and read our periodicals and main publications.
            </Title>
            <Publications />
        </Wrapper>
    </SiteLayout>
);
export default NewStartPage;
